import AUTH from './auth.js'

/* Методы запросов */
export const METHODS = {
	GET: 'GET',
	POST: 'POST',
	PUT: 'PUT',
	PATCH: 'PATCH',
	DELETE: 'DELETE',
	DOWNLOAD: 'DOWNLOAD'
}

/* Имена записей в локальное хранилище */
const LOCAL_STORAGE_DATA = {
	LOCKER_MENU: 'sidebarLockerBlocked'
}

export const NOTIFY_STATUS = {
	SUCCESS: 'success',
	ERROR: 'error'
}

export const SEARCH_ENGINE = {
	YANDEX: 'yandex',
	GOOGLE: 'google'
}

export const OPTIONS_SOURCE = [
	{
		id: SEARCH_ENGINE.YANDEX,
		text: 'Яндекс'
	},
	{
		id: SEARCH_ENGINE.GOOGLE,
		text: 'Google'
	}
]

export default {
	METHODS,
	LOCAL_STORAGE_DATA,
	NOTIFY_STATUS,
	OPTIONS_SOURCE,
	...AUTH
}
