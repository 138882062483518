const getters = {
	defaultChapterId: (state) => {
		if (state.chapters) {
			const defaultFolder = state.chapters.find(folder => folder.is_default === '1')
			return defaultFolder && defaultFolder.id
		}
	}
}

export default getters
