const OPTIONS_VENDOR = ['seoapi', 'yandex_searchapi', 'xml_river', 'fluke']
const OPTIONS_STRATEGY = ['out_5_updates', 'top5_high_frequency', 'top11_high_frequency', 'top15_low_frequency']

const OPTIONS_STATUS = [
	{
		id: 0,
		text: 'Не обработали'
	},
	{
		id: 1,
		text: 'В работе'
	},
	{
		id: 2,
		text: 'Выполнено'
	}
]

const OPTIONS_LIMIT = [100, 500, 1000]

const DEFAULT_LIMIT = 100

export { OPTIONS_STATUS, OPTIONS_VENDOR, OPTIONS_STRATEGY, OPTIONS_LIMIT, DEFAULT_LIMIT }
