import Vue from 'vue'

const mutations = {
	setSessionsLog (state, data) {
		Vue.set(state, 'sessionsLog', data)
	},
	setFlukeSessionsLog (state, data) {
		Vue.set(state, 'flukeSessionsLog', data)
	}
}

export default mutations
