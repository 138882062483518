var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-autocomplete',_vm._b({attrs:{"search-input":_vm.searchInput},on:{"update:searchInput":function($event){_vm.searchInput=$event},"update:search-input":function($event){_vm.searchInput=$event},"input":_vm.selectProject},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('span',[_c('sup',[_vm._v(_vm._s(item.id))]),_vm._v(" "+_vm._s(item.name))])]}},{key:"append-item",fn:function(){return [_c('div',{directives:[{name:"intersect",rawName:"v-intersect",value:(_vm.endIntersect),expression:"endIntersect"}]})]},proxy:true}])},'v-autocomplete',{
		value: _vm.selectValue,
		items: _vm.searchInput ? _vm.projects.search.items : _vm.projects.list,
		outlined: true,
		dense: true,
		hideDetails: true,
		light: true,
		chips: _vm.chips,
		smallChips: _vm.smallChips,
		deletableChips: _vm.deletableChips,
		clearable: true,
		multiple: true,
		itemText: item => `${item.id} ${item.name}`,
		itemValue: _vm.itemValue,
		label: _vm.label || 'Выберите проект',
		loading: _vm.loading,
		returnObject: _vm.returnObject,
		cacheItems: _vm.cacheItems
	},false))
}
var staticRenderFns = []

export { render, staticRenderFns }