<template>
	<div>
		<h1 class="mb-4">{{ title }}</h1>
		<v-filters
			class="mb-4"
			v-bind="{
				filters: urlFilters,
				loading
			}"
			@update-filters="updateUrl"
		/>
		<v-tabs
			:value="urlFilters.source"
			class="mb-1"
			grow
		>
			<v-tab
				v-for="item of OPTIONS_SOURCE"
				:key="item.id"
				:tab-value="item.id"
				@change="updateUrl({source: item.id})"
			>
				<span>{{ item.text }}</span>
			</v-tab>
		</v-tabs>
		<v-data-table
			class="sessions-table"
			:headers="headers"
			:items="updatesSaved"
			:loading="loading.table"
			:server-items-length="updatesSaved.length"
			hide-default-footer
			disable-pagination
			@update:sort-by="updateSortBy"
			@update:sort-desc="updateSortDesc"
		>
			<template v-slot:item.client_id="{ item }">
				<span><sup>{{ item.client_id }}</sup> {{ findClientName(item.client_id) }}</span>
			</template>
			<template v-slot:item.project_id="{ item }">
				<span><sup>{{ item.project_id }}</sup> {{ item.project_name }}</span>
			</template>
			<template v-slot:item.host_name="{ item }">
				<span class="text-no-wrap">{{ item.host_name }}</span>
			</template>
			<template v-slot:item.date="{ item }">
				<span class="text-no-wrap">{{ item.date }}</span>
			</template>
			<template v-slot:item.active_queries_cnt="{ item }">
				<span v-if="item.active_queries_cnt !== undefined && item.active_queries_cnt !== null">
					{{ item.active_queries_cnt.toLocaleString() }}
				</span>
				<span v-else>-</span>
			</template>
			<template v-slot:item.processed_queries_cnt="{ item }">
				<span v-if="item.processed_queries_cnt !== undefined && item.processed_queries_cnt !== null">
					{{ item.processed_queries_cnt.toLocaleString() }}
				</span>
				<span v-else>-</span>
			</template>
			<template v-slot:item.ch_saved_prc="{ item }">
				<strong
					v-if="item.ch_saved_prc !== undefined && item.ch_saved_prc !== null"
				>
					{{ item.ch_saved_prc.toLocaleString({ maximumFractionDigits: 3 }) }}%
				</strong>
				<span v-else>-</span>
			</template>
		</v-data-table>
	</div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { isEqual } from 'lodash'
import { NOTIFY_STATUS, OPTIONS_SOURCE } from '@/const'
import errors from '@/store/errors'
import { validateFilterFromOptions } from '@/function/validateFilter'

import updateUrlMixin from '@/mixins/updateUrlMixin'
import filters from './components/filters.vue'

const SORTING_AVAILABLE = [
	'date',
	'client_id',
	'project_id',
	'ch_saved_prc',
	'shard_name',
	'host_name'
]

export default {
	name: 'saved',
	metaInfo () {
		return {
			title: this.title
		}
	},
	mixins: [updateUrlMixin],
	components: {
		'v-filters': filters
	},
	data () {
		return {
			title: 'Объемы сборов по проектам ch-proxy',
			headers: [
				{
					text: 'Клиент',
					value: 'client_id',
					align: 'center',
					divider: true
				},
				{
					text: 'Проект',
					value: 'project_id',
					align: 'center',
					divider: true
				},
				{
					text: 'Дата',
					value: 'date',
					align: 'center',
					divider: true
				},
				{
					text: 'Кол-во активных запросов',
					value: 'active_queries_cnt',
					align: 'center',
					sortable: false,
					divider: true
				},
				{
					text: 'Кол-во обработанных запросов',
					value: 'processed_queries_cnt',
					align: 'center',
					sortable: false,
					divider: true
				},
				{
					text: '% сохранено',
					value: 'ch_saved_prc',
					align: 'center',
					divider: true
				},
				{
					text: 'Шард',
					value: 'shard_name',
					divider: true
				},
				{
					text: 'Хост CH',
					value: 'host_name',
					divider: true
				}
			],
			loading: {
				clients: true,
				shards: true,
				table: true
			},
			OPTIONS_SOURCE
		}
	},
	computed: {
		...mapState({
			clients: state => state.clients,
			projects: state => state.options.projects,
			shards: state => state.updates.shards,
			hosts: state => state.updates.hosts,
			updatesSaved: state => state.updates.updatesSaved
		}),
		urlFilters () {
			const {
				date_from,
				date_to,
				client_id,
				project_id,
				source,
				shard_name,
				host_name,
				only_failed,
				sort
			} = this.$route.query

			const today = new Date().toISOString().split('T')[0]

			const sortName = sort ? String(sort).replace('-', '') : 'ch_saved_prc'
			const sortNameValidated = validateFilterFromOptions(sortName, SORTING_AVAILABLE, 'ch_saved_prc')
			const sortWithDirection = String(sort).startsWith('-') ? `-${sortNameValidated}` : sortNameValidated
			const projectId = Array.isArray(project_id) ? project_id : [project_id]

			return {
				dateFrom: date_to && date_from ? date_from : today,
				dateTo: date_to && date_from ? date_to : today,
				clientId: validateFilterFromOptions(Array.isArray(client_id) ? client_id : [client_id], this.clients, [], true, true),
				projectId: projectId
					.map(item => +item)
					.filter(item => !isNaN(item)),
				source: validateFilterFromOptions(source, OPTIONS_SOURCE, 'yandex'),
				shardName: validateFilterFromOptions(shard_name, this.shards, [], true),
				hostName: validateFilterFromOptions(host_name, this.hosts, [], true),
				onlyFailed: String(only_failed) === 'true' ? 1 : 0,
				sort: [sortWithDirection]
			}
		}
	},
	async created () {
		try {
			await Promise.all([
				this.getClients(),
				this.initDataShardsAndHosts()
			])
		} catch (_) {
			this.$notify({
				type: NOTIFY_STATUS.ERROR,
				title: errors.GET_DATA
			})
		} finally {
			this.loading.clients = false
		}
		await this.initDataTable()
	},
	watch: {
		async '$route.query' (value, oldValue) {
			const filtersShards = [
				'date_from',
				'date_to',
				'client_id',
				'project_id',
				'source'
			]
			try {
				if (filtersShards.some(filter => !isEqual(value[filter], oldValue[filter]))) {
					await this.initDataShardsAndHosts()
				}
				this.initDataTable()
			} catch (_) {
				this.$notify({
					type: NOTIFY_STATUS.ERROR,
					title: errors.GET_DATA
				})
			}
		}
	},
	methods: {
		...mapActions({
			getClients: 'getClients',
			getUpdatesSaved: 'updates/getUpdatesSaved',
			getUpdatesShards: 'updates/getUpdatesShards'
		}),
		async initDataTable () {
			const {
				dateFrom,
				dateTo,
				clientId,
				projectId,
				source,
				shardName,
				hostName,
				onlyFailed,
				sort
			} = this.urlFilters

			this.loading.table = true
			await this.getUpdatesSaved({
				query: {
					date_from: dateFrom,
					date_to: dateTo,
					client_ids: clientId,
					project_ids: projectId,
					source: source,
					shard_names: shardName,
					host_names: hostName,
					only_failed: onlyFailed,
					sort
				}
			})
			this.loading.table = false
		},
		async initDataShardsAndHosts () {
			const {
				dateFrom,
				dateTo,
				clientId,
				projectId,
				source
			} = this.urlFilters

			this.loading.shards = true
			await this.getUpdatesShards({
				query: {
					date_from: dateFrom,
					date_to: dateTo,
					client_ids: clientId.length || projectId.length ? clientId : ['00'],
					project_ids: projectId,
					source: source
				}
			})
			this.loading.shards = false
		},
		updateSortBy (name) {
			this.updateUrl({ sort: name })
		},
		updateSortDesc (value) {
			if (value !== undefined) {
				const sortName = this.urlFilters.sort[0].replace('-', '')
				this.updateUrl({ sort: `${value ? '-' : ''}${sortName}` })
			}
		},
		findClientName (id) {
			if (this.clients && Array.isArray(this.clients)) {
				const client = this.clients.find((el) => +el.id === +id)
				return client && client.name ? client.name : ''
			}
			return ''
		}
	}
}
</script>
