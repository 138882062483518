<template>
	<div>
		<div class="menu-line">
			<v-autocomplete
				class="menu-line__item"
				v-model="filters.clientId"
				:items="clients"
				outlined
				dense
				clearable
				hideDetails
				light
				:itemText="item => `${item.id} ${item.name}`"
				item-value="id"
				label="Клиент"
				:filter="filterFunction"
				:loading="loadingClients"
			>
				<template v-slot:item="{ item }">
					<span><sup>{{item.id}}</sup> {{item.name}}</span>
				</template>
			</v-autocomplete>
			<v-select-project
				class="menu-line__item"
				v-model="filters.projectId"
				label="Проект"
			/>
			<v-menu
				v-model="menu"
				:close-on-content-click="false"
				:nudge-right="40"
				transition="scale-transition"
				offset-y
				min-width="auto"
			>
				<template v-slot:activator="{ on, attrs }">
					<div class="d-flex align-center">
						<v-text-field
							class="menu-line__item"
							v-model="dateRangeText"
							label="Даты"
							prepend-icon="mdi-calendar"
							readonly
							outlined
							hide-details
							dense
							v-bind="attrs"
							v-on="on"
						/>
					</div>
				</template>
				<v-date-picker
					v-model="filters.dates"
					range
					locale="ru"
					first-day-of-week="1"
					scrollable
					no-title
					:max="new Date().toISOString().substr(0, 10)"
				>
				</v-date-picker>
			</v-menu>
		</div>
		<div class="menu-line">
			<v-autocomplete
				class="menu-line__item"
				v-model="filters.status"
				:items="OPTIONS_STATUS"
				v-bind="commonSelectConfig"
				label="Статус сбора"
				item-value="id"
				multiple
				clearable
			/>
			<v-autocomplete
				class="menu-line__item"
				v-model="filters.vendor"
				:items="OPTIONS_VENDOR"
				v-bind="commonSelectConfig"
				label="Источник сбора"
				multiple
				clearable
			/>
			<v-autocomplete
				class="menu-line__item"
				v-model="filters.source"
				:items="OPTIONS_SOURCE"
				v-bind="commonSelectConfig"
				label="Поисковая система"
				item-value="id"
				multiple
				clearable
			/>
			<v-autocomplete
				class="menu-line__item"
				v-model="filters.strategy"
				:items="OPTIONS_STRATEGY"
				v-bind="commonSelectConfig"
				label="Стратегия пересбора"
				multiple
				clearable
			/>
		</div>
	</div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import updateUrl from '@/mixins/updateUrlMixin'
import { OPTIONS_SOURCE } from '@/const'
import { OPTIONS_STATUS, OPTIONS_VENDOR, OPTIONS_STRATEGY } from './filtersOptions'
import selectProject from '@/components/selectProject'
import { validateFilterNumber } from '@/function/validateFilter'

export default {
	components: {
		'v-select-project': selectProject
	},
	mixins: [updateUrl],
	props: {
		urlFilters: {
			type: Object,
			required: true
		}
	},
	data () {
		return {
			filters: {
				dates: [],
				clientId: undefined,
				projectId: null,
				status: [],
				vendor: [],
				source: [],
				strategy: []

			},
			loadingClients: false,
			menu: false,
			OPTIONS_STATUS,
			OPTIONS_VENDOR,
			OPTIONS_SOURCE,
			OPTIONS_STRATEGY,
			commonSelectConfig: {
				outlined: true,
				dense: true,
				hideDetails: true,
				light: true,
				clearable: true
			}
		}
	},
	async mounted () {
		await this.requestClients()
		this.setFilters()
	},
	computed: {
		...mapState({
			clients: state => state.clients
		}),
		dateRangeText () {
			return this.sortDates(this.filters.dates).map((el) => this.formatDate(el)).join(' - ')
		}
	},
	methods: {
		...mapActions({
			getClients: 'getClients'
		}),
		setFilters () {
			for (const key in this.urlFilters) {
				switch (key) {
				case 'clientId':
				case 'projectId':
					this.filters[key] = !isNaN(this.urlFilters[key]) ? +this.urlFilters[key] : undefined
					break
				case 'dateFrom':
				case 'dateTo':
					if (!this.filters.dates.length) {
						this.filters.dates = this.sortDates([this.urlFilters.dateFrom, this.urlFilters.dateTo])
					}
					break
				default:
					if (this.urlFilters[key]) {
						this.filters[key] = this.urlFilters[key]
					}
				}
			}
		},
		sortDates (array) {
			const copy = [...array]
			return copy.sort(function (a, b) {
				return new Date(a) - new Date(b)
			})
		},
		formatDate (date) {
			return date.split('-').reverse().join('.')
		},
		calcDates (filtersDates) {
			const today = new Date().toISOString().split('T')[0]
			let dateFrom = today
			let dateTo = today

			const dates = this.sortDates(filtersDates)

			if (Array.isArray(filtersDates) && dates.length === 2 && dates[0] && dates[1]) {
				dateFrom = dates[0]
				dateTo = dates[1]
			}
			return { dateFrom, dateTo }
		},
		filterFunction (item, queryText, itemText) {
			return itemText.toLocaleLowerCase().indexOf(queryText.trim().toLocaleLowerCase()) > -1 || item.id.toString().indexOf(queryText.trim().toString()) > -1
		},
		async requestClients () {
			this.loadingClients = true
			await this.getClients()
			this.loadingClients = false
		}
	},
	watch: {
		filters: {
			async handler (val) {
				if (!val.dates || !val.dates.length) return
				if (val.dates.length === 1) return
				const { dateFrom, dateTo } = this.calcDates(val.dates)
				this.updateUrl({
					date_from: dateFrom,
					date_to: dateTo,
					client_id: val.clientId != null ? val.clientId : undefined,
					project_id: validateFilterNumber(val.projectId),
					status: val.status && val.status.length ? val.status : undefined,
					vendor: val.vendor && val.vendor.length ? val.vendor : undefined,
					source: val.source && val.source.length ? val.source : undefined,
					strategy: val.strategy && val.strategy.length ? val.strategy : undefined
				})
			},
			deep: true
		}
	}
}
</script>

<style lang="scss" scoped>
.menu-line {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 10px;
	margin-bottom: 24px;

	&__item {
		max-width: 300px;
	}
}
</style>
