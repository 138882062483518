var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"menu-line"},[_c('v-select-project',{staticClass:"menu-line__item",attrs:{"label":"Проект"},model:{value:(_vm.filters.projectId),callback:function ($$v) {_vm.$set(_vm.filters, "projectId", $$v)},expression:"filters.projectId"}}),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',{staticClass:"d-flex align-center"},[_c('v-text-field',_vm._g(_vm._b({staticClass:"menu-line__item",attrs:{"label":"Дата","prepend-icon":"mdi-calendar","readonly":"","outlined":"","hide-details":"","dense":""},model:{value:(_vm.filters.date),callback:function ($$v) {_vm.$set(_vm.filters, "date", $$v)},expression:"filters.date"}},'v-text-field',attrs,false),on))],1)]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"locale":"ru","first-day-of-week":"1","scrollable":"","no-title":"","max":new Date().toISOString().substr(0, 10)},model:{value:(_vm.filters.date),callback:function ($$v) {_vm.$set(_vm.filters, "date", $$v)},expression:"filters.date"}})],1),_c('v-autocomplete',_vm._b({staticClass:"menu-line__item",attrs:{"items":_vm.OPTIONS_STRATEGY},model:{value:(_vm.filters.strategy),callback:function ($$v) {_vm.$set(_vm.filters, "strategy", $$v)},expression:"filters.strategy"}},'v-autocomplete',{
				outlined: true,
				dense: true,
				hideDetails: true,
				light: true,
				clearable: true,
				label: 'Стратегия пересбора'
			},false)),_c('v-autocomplete',_vm._b({staticClass:"menu-line__item",attrs:{"items":_vm.OPTIONS_LIMIT},model:{value:(_vm.filters.limit),callback:function ($$v) {_vm.$set(_vm.filters, "limit", $$v)},expression:"filters.limit"}},'v-autocomplete',{
				outlined: true,
				dense: true,
				hideDetails: true,
				light: true,
				label: 'Лимит запросов на странице'
			},false))],1),_c('v-tabs',{staticClass:"mb-1",attrs:{"value":_vm.filters.source,"grow":""}},_vm._l((_vm.OPTIONS_SOURCE),function(item){return _c('v-tab',{key:item.id,attrs:{"tab-value":item.id},on:{"change":function($event){return _vm.changeTab(item.id)}}},[_c('span',[_vm._v(_vm._s(item.text))])])}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }