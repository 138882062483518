<template>
	<div>
		<div class="menu-line">
			<v-text-field
				class="menu-line__item"
				v-model="filters.sessionId"
				label="ID сессии"
				outlined
				hide-details
				dense
				clearable
			/>
			<v-menu
				v-model="menu"
				:close-on-content-click="false"
				:nudge-right="40"
				transition="scale-transition"
				offset-y
				min-width="auto"
			>
				<template v-slot:activator="{ on, attrs }">
					<div class="d-flex align-center">
						<v-text-field
							class="menu-line__item"
							v-model="dateRangeText"
							label="Даты"
							prepend-icon="mdi-calendar"
							readonly
							outlined
							hide-details
							dense
							v-bind="attrs"
							v-on="on"
						></v-text-field>
					</div>
				</template>
				<v-date-picker
					v-model="filters.dates"
					range
					locale="ru"
					first-day-of-week="1"
					scrollable
					no-title
					:max="new Date().toISOString().substr(0, 10)"
				>
				</v-date-picker>
			</v-menu>
			<v-autocomplete
        v-if="isSeoapiLogPage"
				class="menu-line__item"
				v-model="filters.dataType"
				:items="OPTIONS_DATA_TYPE"
				v-bind="commonSelectConfig"
				label="Тип данных"
			/>
		</div>
		<div class="menu-line">
			<v-autocomplete
				class="menu-line__item"
				v-model="filters.sessionProcessingStatus"
				:items="OPTIONS_PROCESSING_STATUS"
				v-bind="commonSelectConfig"
				label="Статус обработки сессии"
			/>
			<v-autocomplete
        v-if="isSeoapiLogPage"
				class="menu-line__item"
				v-model="filters.recollect"
				:items="OPTIONS_RECOLLECT"
				v-bind="commonSelectConfig"
				label="Статус пересбора"
			/>
			<v-autocomplete
				class="menu-line__item"
				v-model="filters.source"
				:items="OPTIONS_SOURCE"
				v-bind="commonSelectConfig"
				label="Поисковая система"
			/>
			<v-autocomplete
				class="menu-line__item"
				v-model="filters.device"
				:items="optionsDevice"
				v-bind="commonSelectConfig"
				label="Устройство"
			/>
		</div>
	</div>
</template>

<script>
import { OPTIONS_SOURCE } from '@/const'
import updateUrl from '@/mixins/updateUrlMixin'
import { OPTIONS_DATA_TYPE, OPTIONS_PROCESSING_STATUS, OPTIONS_RECOLLECT } from '../filtersOptions'

export default {
	mixins: [updateUrl],
	props: {
		urlFilters: {
			type: Object,
			required: true
		},
		optionsDevice: {
			type: Array,
			default: () => []
		}
	},
	data () {
		return {
			filters: {
				dates: [],
				sessionId: '',
				recollect: '',
				dataType: '',
				sessionProcessingStatus: '',
				device: '',
				source: ''

			},
			menu: false,
			OPTIONS_DATA_TYPE,
			OPTIONS_PROCESSING_STATUS,
			OPTIONS_RECOLLECT,
			OPTIONS_SOURCE,
			commonSelectConfig: {
				outlined: true,
				dense: true,
				hideDetails: true,
				light: true,
				clearable: true,
				itemValue: 'id'
			}
		}
	},
	created () {
		this.setFilters()
	},
	computed: {
		dateRangeText () {
			return this.sortDates(this.filters.dates).map((el) => this.formatDate(el)).join(' - ')
		},
		isSeoapiLogPage () {
			return this.$route.name === 'data-sessions-log'
		}
	},
	methods: {
		setFilters () {
			for (const key in this.urlFilters) {
				if ((key === 'dateFrom' || key === 'dateTo') && !this.filters.dates.length) {
					this.filters.dates = this.sortDates([this.urlFilters.dateFrom, this.urlFilters.dateTo])
				} else {
					if (this.urlFilters[key]) {
						this.filters[key] = this.urlFilters[key]
					}
				}
			}
		},
		sortDates (array) {
			const copy = [...array]
			return copy.sort(function (a, b) {
				return new Date(a) - new Date(b)
			})
		},
		formatDate (date) {
			return date.split('-').reverse().join('.')
		},
		calcDates (filtersDates) {
			const today = new Date().toISOString().split('T')[0]
			let dateFrom = today
			let dateTo = today

			const dates = this.sortDates(filtersDates)

			if (Array.isArray(filtersDates) && dates.length === 2 && dates[0] && dates[1]) {
				dateFrom = dates[0]
				dateTo = dates[1]
			}
			return { dateFrom, dateTo }
		}
	},
	watch: {
		filters: {
			async handler (val) {
				if (!val.dates || !val.dates.length) return
				if (val.dates.length === 1) return
				const { dateFrom, dateTo } = this.calcDates(val.dates)
				this.updateUrl({
					date_from: dateFrom,
					date_to: dateTo,
					session_id: val.sessionId || undefined,
					recollect: val.recollect === 'true' || val.recollect === 'false' ? val.recollect : undefined,
					session_processing_status: val.sessionProcessingStatus || undefined,
					data_type: val.dataType || undefined,
					device: val.device || undefined,
					source: val.source || undefined
				})
			},
			deep: true
		}
	}
}
</script>

<style lang="scss" scoped>
.menu-line {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 10px;
	margin-bottom: 24px;

	&__item {
		max-width: 300px;
	}
}
</style>
