import { isNaN, isNull, isObjectLike } from 'lodash'

function validateArray (value, options, isNumber) {
	const valueFiltered = value.filter(item => {
		return item !== null &&
			item !== undefined &&
			findOption(item, options, isNumber)
	})
	return isNumber
		? valueFiltered
			.map(item => +item)
			.filter(item => !isNaN(item))
		: valueFiltered
}

function findOption (value, options, isNumber) {
	return options.find(option => {
		if (isObjectLike(option)) {
			const valueFormatted = isNumber ? +value : value
			return option.id === valueFormatted
		}
		return option === value
	})
}

export function validateFilterNumber (value, defaultValue) {
	if (!isNull(value) && !isNaN(value) && value !== undefined) {
		return value
	} else if (!isNull(defaultValue) && defaultValue !== undefined) {
		return defaultValue
	} else {
		return undefined
	}
}

export function validateFilterFromOptions (value, options = [], defaultValue, multiple, isNumber) {
	if (multiple) {
		if (value) {
			if (Array.isArray(value)) {
				return validateArray(value, options, isNumber)
			} else {
				return validateArray([value], options)
			}
		} else {
			return defaultValue || []
		}
	} else {
		if (value !== undefined && findOption(value, options)) {
			return value
		} else if (defaultValue !== undefined) {
			return defaultValue
		} else {
			return undefined
		}
	}
}
