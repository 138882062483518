import Vue from 'vue'

const mutations = {
	setRecollectData (state, data) {
		Vue.set(state, 'recollectData', data)
	},

	setRecollectQueries (state, data) {
		Vue.set(state, 'recollectQueries', data)
	}
}

export default mutations
