import Vue from 'vue'
import errors from '@/store/errors'
import { METHODS } from '@/const'
const vue = Vue.prototype
const actions = {
	getRecollectData: async function ({ state, commit, getters, rootState }, input) {
		commit('setRecollectData', [])
		try {
			const result = await rootState.request({
				type: METHODS.GET,
				apiName: 'hqsw',
				url: `bridge/seowork/organic/recollected-query/statistics/${input.dateFrom}/${input.dateTo}`,
				auth: true,
				query: input.query
			})

			if (result.has_data && result.data) {
				commit('setRecollectData', result.data)
				return result.data
			}
		} catch (e) {
			vue.$notify({ type: 'error', title: errors.GET_DATA })
			return false
		}
	},

	getRecollectQueries: async function ({ state, commit, getters, rootState }, input) {
		commit('setRecollectQueries', [])
		try {
			const result = await rootState.request({
				type: METHODS.GET,
				apiName: 'hqsw',
				url: `bridge/seowork/organic/recollected-queries/${input.date}/${input.projectId}/${input.source}`,
				auth: true,
				query: input.query
			})

			if (result.has_data && result.data) {
				commit('setRecollectQueries', result.data)
				return result.data
			}
		} catch (e) {
			vue.$notify({ type: 'error', title: errors.GET_DATA })
			return false
		}
	}
}
export default actions
