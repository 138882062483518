<template>
	<div>
		<h1 class="main-title">{{title}}</h1>
		<v-filters :urlFilters="urlFilters"/>
		<br/>
		<br/>
		<p class="recollect-total">Всего: {{ sortedItems.length }}</p>
		<v-data-table
			class="recollect-table"
			:headers="headers"
			:items="sortedItems"
			:loading="loading"
			hide-default-footer
			disable-pagination
		>
      <template v-slot:item.cnt="{ item }">
        <td class="text-center v-data-table__divider">
          <router-link class="recollect-table__queries-link" :to="getQueriesLink(item)" target="_blank">{{item.cnt}}</router-link>
        </td>
      </template>
    </v-data-table>
	</div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import { isEqual, debounce } from 'lodash'
import { OPTIONS_SOURCE } from '@/const'
import { OPTIONS_STATUS, OPTIONS_VENDOR, OPTIONS_STRATEGY } from './filtersOptions'
import { validateFilterFromOptions, validateFilterNumber } from '@/function/validateFilter'

import filters from './filters.vue'

export default {
	metaInfo () {
		return {
			title: this.title
		}
	},
	components: {
		'v-filters': filters
	},
	data () {
		return {
			debouncedInitData: null,
			title: 'Объёмы пересборов',
			loading: false,
			headers: [
				{
					text: 'Дата',
					value: 'date',
					align: 'center',
					divider: true
				},
				{
					text: 'ID клиента',
					value: 'client_id',
					align: 'center',
					divider: true
				},
				{
					text: 'ID проекта',
					value: 'project_id',
					align: 'center',
					divider: true
				},
				{
					text: 'Поисковая система',
					value: 'source',
					align: 'center',
					divider: true
				},
				{
					text: 'Источник сбора',
					value: 'vendor',
					align: 'center',
					divider: true
				},
				{
					text: 'Мин. WS',
					value: 'min_ws',
					align: 'center',
					divider: true
				},
				{
					text: 'Макс. WS',
					value: 'max_ws',
					align: 'center',
					divider: true
				},
				{
					text: 'Стратегия',
					value: 'strategy',
					align: 'center',
					divider: true
				},
				{
					text: 'Количество',
					value: 'cnt',
					align: 'center',
					divider: true
				},
				{
					text: 'Статус сбора',
					value: 'status',
					align: 'center',
					divider: true
				}
			]
		}
	},
	computed: {
		...mapState({
			recollectData: state => state.recollect.recollectData,
			clients: state => state.clients
		}),
		urlFilters () {
			const {
				date_from,
				date_to,
				client_id,
				project_id,
				status,
				vendor,
				source,
				strategy
			} = this.$route.query
			const today = new Date().toISOString().split('T')[0]
			return {
				dateFrom: date_to && date_from ? date_from : today,
				dateTo: date_to && date_from ? date_to : today,
				projectId: validateFilterNumber(+project_id),
				clientId: validateFilterFromOptions(+client_id, this.clients),
				status: validateFilterFromOptions(status, OPTIONS_STATUS, undefined, true, true),
				vendor: validateFilterFromOptions(vendor, OPTIONS_VENDOR, undefined, true),
				source: validateFilterFromOptions(source, OPTIONS_SOURCE, undefined, true),
				strategy: validateFilterFromOptions(strategy, OPTIONS_STRATEGY, undefined, true)
			}
		},
		sortedItems () {
			return this.recollectData.map(item => {
				const status = OPTIONS_STATUS.find(option => +option.id === +item.status)
				return {
					...item,
					timestamp: item.date && new Date(item.date).getTime(),
					status: status ? status.text : item.status
				}
			}).sort((a, b) => b.timestamp - a.timestamp)
		}
	},
	async created () {
		await this.initData()
		this.debouncedInitData = debounce(this.initData, 1000)
	},
	methods: {
		...mapActions({
			getRecollectData: 'recollect/getRecollectData'
		}),
		async initData () {
			const { dateFrom, dateTo, status, clientId, projectId, vendor, strategy, source } = this.urlFilters
			this.loading = true
			await this.getRecollectData({
				dateFrom,
				dateTo,
				query: {
					client_id: clientId != null ? String(clientId) : undefined,
					project_id: projectId,
					statuses: status,
					sources: source,
					vendors: vendor,
					strategies: strategy
				}
			})
			this.loading = false
		},
		getQueriesLink ({ date, project_id, source, strategy }) {
			return {
				name: 'data-recollect-queries',
				query: {
					date,
					project_id,
					source,
					strategy
				}
			}
		}
	},
	watch: {
		'$route.query' (value, oldValue) {
			if (!isEqual(value, oldValue) && this.debouncedInitData) {
				this.debouncedInitData()
			}
		}
	}
}
</script>
<style lang="scss" scoped>
.main-title {
	margin-bottom: 20px;
}
.recollect-total {
  text-align: center;
}
.recollect-table {
	margin-top: 24px;
	border-top: 1px solid rgba(0,0,0,.12);
}
/deep/ .recollect-table__queries-link {
  display: block;
}
</style>
