import Vue from 'vue'

const mutations = {
	/* Запись профиля в хранилище */
	getProfile (state, data) {
		Vue.set(state, 'profile', data)
	},

	/* Запись списка разделов меню */
	setAsideList (state, data) {
		Vue.set(state, 'aside', data)
	},

	/* Запись проектов в хранилище */
	pushProjects (state, payload) {
		const projects = { ...state.options.projects }
		const projectToPush = payload.items.filter(project => !state.options.projects.list.find(item => item.id === project.id))

		if (payload.has_data) {
			if (payload.byProjectId) {
				Vue.set(state.options.projects, 'list', projectToPush.concat(projects.list))
			} else {
				Vue.set(state.options.projects, 'offset', projects.offset + projects.limit)
				Vue.set(state.options.projects, 'next', payload.next && !!payload.items.length)
				Vue.set(state.options.projects, 'list', projects.list.concat(projectToPush))
				Vue.set(state.options.projects, 'total', payload.total)
			}
		}
	},

	/* Запись проектов при поиске */
	pushProjectsSearch (state, payload) {
		const projects = { ...state.options.projects.search }

		if (payload.has_data) {
			Vue.set(state.options.projects.search, 'offset', projects.offset + projects.limit)
			Vue.set(state.options.projects.search, 'next', payload.next && !!payload.items.length)
			Vue.set(state.options.projects.search, 'items', projects.items.concat(payload.items))
		}
	},

	/* Очистка записей поиска */
	clearProjectsSearch (state) {
		Vue.set(state.options.projects.search, 'offset', 0)
		Vue.set(state.options.projects.search, 'next', true)
		Vue.set(state.options.projects.search, 'items', [])
	},

	/* Очистка записей поиска */
	clearProjects (state) {
		Vue.set(state.options.projects, 'offset', 0)
		Vue.set(state.options.projects, 'next', true)
		Vue.set(state.options.projects, 'list', [])
		Vue.set(state.options.projects, 'total', 0)
	},

	/* Запись списка клиентов в хранилище */
	pushClients (state, payload) {
		let clients = []
		if (payload && payload.data && payload.data.items && payload.data.items.active && payload.data.items.active.items && payload.data.items.active.items.length) {
			clients = payload.data.items.active.items
			Vue.set(state, 'clients', clients)
		}
	}
}

export default mutations
