<template>
	<v-card>
		<v-card-title>{{ title }}</v-card-title>
		<v-card-text>
			<v-form
				ref="form"
				v-model="formValid"
				@submit.prevent="submitForm"
			>
				<v-container>
					<v-text-field
						label="Название раздела"
						outlined
						v-model="form.chapterName"
						:rules="[rules.required]"
					/>
					<div class="tree-label">Родительский раздел</div>
					<v-treeview
							v-if="chaptersList && chaptersList.length"
							:items="chaptersList"
							:active.sync="form.chapterParent"
							activatable
						>
						<template v-slot:prepend="{}">
							<v-icon >mdi-folder-open</v-icon>
						</template>
					</v-treeview>
					<span>Раздел доступен пользователям?</span>
						<v-radio-group
							class="mt-0"
							v-model="form.is_active"
							mandatory
						>
						<v-radio
							label="скрыт"
							:value="false"
						></v-radio>
						<v-radio
							label="доступен"
							:value="true"
						></v-radio>
					</v-radio-group>
					<span>Раздел имеет статус "по умолчанию"?</span>
					<v-radio-group
						class="mt-0"
						v-model="form.is_default"
						mandatory
						>
						<v-radio
							label="нет"
							:value="false"
						></v-radio>
						<v-radio
							label="да"
							:value="true"
						></v-radio>
					</v-radio-group>
				</v-container>
				<div class="buttons">
					<v-btn
						class="button cancel-btn"
						@click="closeDialog"
					>
						Отмена
					</v-btn>
					<v-btn
						class="button"
						type="submit"
						:disabled="!hasChanges"
					>
						Сохранить
					</v-btn>
				</div>
			</v-form>
		</v-card-text>
	</v-card>

</template>

<script>

export default {
	props: {
		chaptersList: {
			type: Array
		},
		title: {
			type: String
		},
		chapter: {
			type: Object
		}
	},
	data () {
		return {
			form: {
				chapterName: null,
				chapterParent: [],
				is_active: true,
				is_default: null,
				id: this.chapter ? this.chapter.id : null
			},
			rules: {
				required: value => !!value
			},
			formValid: false
		}
	},

	mounted () {
		if (this.chapter) {
			this.form = {
				chapterName: this.chapter.name,
				chapterParent: this.chapter.parent_id ? [`${this.chapter.parent_id}`] : [],
				is_active: this.chapter.is_active === '1',
				is_default: this.chapter.is_default === '1',
				id: this.chapter.id
			}
			if (this.chapter.children) {
				this.form.children = JSON.parse(JSON.stringify(this.chapter.children))
			}
		}
	},

	computed: {
		hasChanges () {
			if (this.chapter) {
				const itemParent = (!this.chapter.parent_id && []) || [`${this.chapter.parent_id}`]

				return this.form.chapterName !== this.chapter.name ||
				this.form.chapterParent[0] !== itemParent[0] ||
				this.form.is_active !== (this.chapter.is_active === '1') ||
				this.form.is_default !== (this.chapter.is_default === '1')
			}
			return true
		}
	},

	methods: {
		resetForm () {
			this.form = {
				chapterName: undefined,
				chapterParent: [],
				is_active: null,
				is_default: null
			}
		},
		closeDialog () {
			this.resetForm()
			this.$emit('closeDialog')
		},

		submitForm () {
			const isValid = this.$refs.form.validate()
			if (!isValid) {
				return
			}
			if ((this.chapter && this.hasChanges) || !this.chapter) {
				this.$emit('saveChapter', this.form)
				this.closeDialog()
			}
		}
	}
}
</script>

<style scoped>
	.button{
		width: 97px;
		height: 40px;
		color: white;
		background-color: #2979FF !important;
		box-shadow: none !important;
		text-transform: none !important;
		margin-left: 12px;
	}
	.cancel-btn {
		background-color: #F5F5F5 !important;
		color:#455165;
	}
	.buttons {
		text-align: end;
	}
</style>
