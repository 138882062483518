<template>
	<v-autocomplete
		v-bind="{
			value: selectValue,
			items: searchInput ? projects.search.items : projects.list,
			outlined: true,
			dense: true,
			hideDetails: true,
			light: true,
			chips,
			smallChips,
			deletableChips,
			clearable: true,
			multiple: true,
			itemText: item => `${item.id} ${item.name}`,
			itemValue,
			label: label || 'Выберите проект',
			loading,
			returnObject,
			cacheItems: cacheItems
		}"
		:search-input.sync="searchInput"
		@input="selectProject"
	>
		<template v-slot:item="{ item }">
			<span><sup>{{item.id}}</sup> {{item.name}}</span>
		</template>
		<template v-slot:append-item>
			<div v-intersect="endIntersect" />
		</template>
	</v-autocomplete>
</template>

<script>
import { NOTIFY_STATUS } from '@/const'
import { mapActions, mapMutations } from 'vuex'

export default {
	name: 'selectProject',
	props: {
		value: [Array, Number, Object],
		chips: Boolean,
		smallChips: Boolean,
		deletableChips: Boolean,
		returnObject: Boolean,
		multiple: Boolean,
		itemValue: {
			type: String,
			default: 'id'
		},
		clientId: null,
		cacheItems: {
			type: Boolean,
			default: true
		},
		label: String,
		disableClientIdFromUrl: Boolean
	},
	data () {
		return {
			searchInput: null,
			loading: false,
			timeoutSearch: null
		}
	},
	computed: {
		selectValue () {
			if (this.multiple) {
				return this.value
			} else if (this.value !== null && this.value !== undefined) {
				return [this.value]
			} else {
				return []
			}
		},
		projects () {
			return this.$store.state.options.projects
		},
		routeQueryProjectId () {
			const projectId = this.$route.query.project_id
			if (Array.isArray(projectId)) {
				return projectId
					.map(id => +id)
					.filter(id => !isNaN(id))
			} else if (!isNaN(+projectId)) {
				return [+projectId]
			}
			return []
		}
	},
	watch: {
		searchInput (value) {
			if (value) {
				if (this.timeoutSearch) {
					clearTimeout(this.timeoutSearch)
				}
				this.timeoutSearch = setTimeout(async () => {
					await this.fetchProjects({
						search: value,
						firstPage: true
					})
				}, 1000)
			}
		},
		async 'clientId' (val, oldVal) {
			if (val !== oldVal) {
				this.clearProjects()
				await this.fetchProjects({ clientId: this.clientId })
			}
		}
	},
	async mounted () {
		try {
			if (this.routeQueryProjectId.length) {
				await this.fetchProjects({ projectId: this.routeQueryProjectId })
			} else if (this.$route.query.client_id && !this.disableClientIdFromUrl) {
				await this.fetchProjects({ clientId: this.$route.query.client_id })
			}
			await this.fetchProjects()
		} catch ({ message }) {
			this.$notify({ type: NOTIFY_STATUS.ERROR, title: message })
		}
	},
	beforeDestroy () {
		clearTimeout(this.timeoutSearch)
		this.$store.commit('clearProjects')
	},
	methods: {
		...mapMutations(['clearProjects']),
		...mapActions({ getProjects: 'getProjects' }),
		endIntersect (entries, observer, isIntersecting) {
			if (isIntersecting) {
				this.fetchProjects()
			}
		},
		async fetchProjects (data) {
			if (this.projects.next) {
				this.loading = true
				await this.lazyLoadList(data)
				this.loading = false
			}
		},
		lazyLoadList (data) {
			return this.$store.dispatch('getProjects', data)
		},
		selectProject (value) {
			let valueNew
			if (this.multiple || value.length <= 1) {
				valueNew = value
			} else if (value.length === 2) {
				valueNew = [value[1]]
			} else {
				valueNew = []
			}
			if (this.multiple) {
				this.$emit('input', valueNew)
			} else {
				this.$emit('input', valueNew.length ? valueNew[0] : undefined)
			}
		}
	}
}
</script>
