var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"d-flex align-center"},[_c('v-autocomplete',{staticClass:"mr-4 filter-item",attrs:{"value":_vm.filters.clientId,"items":_vm.clients,"outlined":"","dense":"","clearable":"","hideDetails":"","item-text":"name","item-value":"id","label":"Клиент","multiple":"","filter":_vm.filterByText,"loading":_vm.loading.clients},on:{"input":function($event){return _vm.updateFilters('client_id', $event)}},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('span',[_c('sup',[_vm._v(_vm._s(item.id))]),_vm._v(" "+_vm._s(item.name))])]}}])}),_c('v-select-project',{staticClass:"mr-4 filter-item",attrs:{"value":_vm.filters.projectId,"multiple":"","label":"Проект","disable-client-id-from-url":""},on:{"input":function($event){return _vm.updateFilters('project_id', $event)}}}),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',{staticClass:"d-flex align-center"},[_c('v-text-field',_vm._g(_vm._b({staticClass:"filter-item"},'v-text-field',{
							...attrs,
							value: _vm.datePickerInputValue,
							label: 'Выберите даты',
							prependIcon: 'mdi-calendar',
							readonly: true,
							outlined: true,
							hideDetails: true,
							dense: true
						},false),on))],1)]}}]),model:{value:(_vm.menuDatePicker),callback:function ($$v) {_vm.menuDatePicker=$$v},expression:"menuDatePicker"}},[_c('v-date-picker',{attrs:{"value":_vm.filterDates,"range":"","locale":"ru","first-day-of-week":"1","scrollable":"","no-title":"","max":new Date().toISOString().substr(0, 10)},on:{"input":function($event){return _vm.updateFilters('date', $event)}}})],1)],1),_c('div',{staticClass:"d-flex align-center"},[_c('v-autocomplete',{staticClass:"mr-4 filter-item",attrs:{"value":_vm.filters.shardName,"items":_vm.shards,"outlined":"","dense":"","clearable":"","hideDetails":"","label":"Шард","multiple":"","filter":_vm.filterByText,"loading":_vm.loading.shards},on:{"input":function($event){return _vm.updateFilters('shard_name', $event)}},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(item))])]}}])}),_c('v-autocomplete',{staticClass:"mr-4 filter-item",attrs:{"value":_vm.filters.hostName,"items":_vm.hosts,"outlined":"","dense":"","clearable":"","hideDetails":"","label":"Хост CH","multiple":"","filter":_vm.filterByText,"loading":_vm.loading.shards},on:{"input":function($event){return _vm.updateFilters('host_name', $event)}},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(item))])]}}])}),_c('v-checkbox',{attrs:{"label":"Только недособранные апдейты"},on:{"change":function($event){return _vm.updateFilters('only_failed', $event)}},model:{value:(_vm.filters.onlyFailed),callback:function ($$v) {_vm.$set(_vm.filters, "onlyFailed", $$v)},expression:"filters.onlyFailed"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }