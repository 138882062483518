/* Modules */
import i18n from './i18n'
import save from './save'
import sessions from './sessions'
import segments from './segments'
import frequency from './frequency'
import gsc from './gsc'
import collections from './collections'
import projectIntegration from './dataforce/projectIntegration'
import features from './features'
import ctr from './ctr'
import sessionsLog from './sessionsLog'
import updates from './updates'
import recollect from './recollect'

export default {
	i18n,
	save,
	sessions,
	segments,
	frequency,
	gsc,
	projectIntegration,
	collections,
	features,
	ctr,
	sessionsLog,
	updates,
	recollect
}
