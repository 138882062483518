import constants from '@/const/'
import errors from '@/store/errors'

const actions = {
	/* Загрузка профиля */
	getProfile: function ({ state, commit, getters, rootState }, input) {
		return new Promise((resolve, reject) => {
			rootState.request({
				type: constants.METHODS.GET,
				url: '/user/profile',
				auth: true
			}).then(result => {
				commit('getProfile', result.data)
				resolve(true)
			}, () => {
				// reject(errors.PROFILE)
			})
		})
	},

	/* Запрос списка меню */
	getAsideList: function ({ state, commit, getters, rootState }, input) {
		return new Promise((resolve, reject) => {
			rootState.request({
				type: constants.METHODS.GET,
				url: '/menu',
				auth: true
			}).then(result => {
				if (result.data) {
					commit('setAsideList', result.data)
					resolve(true)
				} else {
					// reject(errors.GET_ASIDE)
				}
			}, () => {
				// reject(errors.GET_ASIDE)
			})
		})
	},

	getProjects: function ({ state, commit, getters, rootState }, input) {
		return new Promise((resolve, reject) => {
			const projects = { ...state.options.projects }
			const query = {}

			if (input && input.search) {
				if (input.firstPage) {
					commit('clearProjectsSearch')
				}

				query.limit = projects.search.limit
				query.offset = projects.search.offset
				query.q = input && input.search ? input.search : undefined
			} else {
				if (!state.options.projects.next) {
					resolve({ next: false })
				}

				if (input && input.projectId && input.projectId.length) {
					query.project_id = input.projectId
				} else if (input && input.clientId) {
					query.client_id = input.clientId
				} else {
					query.limit = projects.limit
					query.offset = projects.offset
				}
			}

			rootState.request({
				type: constants.METHODS.GET,
				apiName: 'seowork',
				url: '/projects',
				query: {
					active: 1,
					...query
				},
				auth: true
			}).then(result => {
				if (input && input.search && input.search !== '') {
					commit('pushProjectsSearch', {
						has_data: result.has_data,
						next: result.data.has_more,
						items: result.data.items
					})
				} else {
					commit('pushProjects', {
						has_data: result.has_data,
						next: result.data.has_more,
						items: result.data.items,
						byProjectId: !!(input && input.projectId && input.projectId.length),
						total: result.data.total
					})
				}

				resolve({ next: result.data.has_more && !!result.data.items.length })
			}, () => {
				reject(Error(errors.GET_DATA))
			})
		})
	},
	getClients ({ commit, rootState }, input) {
		return new Promise((resolve, reject) => {
			rootState.request({
				type: constants.METHODS.GET,
				apiName: 'seowork',
				url: '/clients',
				auth: true,
				query: input
			}).then(result => {
				commit('pushClients', result)
				resolve(true)
			}, () => {
				reject(Error(errors.GET_DATA))
			})
		})
	}
}

export default actions
