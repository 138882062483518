import Vue from 'vue'
import errors from '@/store/errors'
import { METHODS } from '@/const'
const vue = Vue.prototype

const actions = {
	getSessionsLog: async function ({ state, commit, getters, rootState }, input) {
		commit('setSessionsLog', [])
		try {
			const result = await rootState.request({
				type: METHODS.GET,
				apiName: 'hqsw',
				url: 'bridge/seoapi/session_log',
				auth: true,
				query: input.query
			})

			if (result.has_data && result.data) {
				commit('setSessionsLog', result.data)
				return result.data
			}
		} catch (e) {
			vue.$notify({ type: 'error', title: errors.GET_DATA })
			return false
		}
	},
	getFlukeSessionsLog: async function ({ state, commit, getters, rootState }, input) {
		commit('setFlukeSessionsLog', [])
		try {
			const result = await rootState.request({
				type: METHODS.GET,
				apiName: 'hqsw',
				url: 'bridge/fluke/session_log',
				auth: true,
				query: input.query
			})

			if (result.has_data && result.data) {
				commit('setFlukeSessionsLog', result.data)
				return result.data
			}
		} catch (e) {
			vue.$notify({ type: 'error', title: errors.GET_DATA })
			return false
		}
	}
}

export default actions
