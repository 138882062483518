import { render, staticRenderFns } from "./projectsCollection.vue?vue&type=template&id=ec2f8d00&scoped=true"
import script from "./projectsCollection.vue?vue&type=script&lang=js"
export * from "./projectsCollection.vue?vue&type=script&lang=js"
import style0 from "./projectsCollection.vue?vue&type=style&index=0&id=ec2f8d00&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ec2f8d00",
  null
  
)

export default component.exports