const mutations = {
	setUpdatesSaved (state, data) {
		if (data && Array.isArray(data)) {
			state.updatesSaved = data
		} else {
			state.updatesSaved = []
		}
	},

	setUpdatesShards (state, data) {
		if (data) {
			const clusters = Object.keys(data)
			const shards = []
			const hosts = []
			clusters.forEach(cluster => {
				const shardKey = Object.keys(data[cluster])
				shards.push(...shardKey)

				shardKey.forEach(key => {
					hosts.push(...Object.values(data[cluster][key]))
				})
			})
			state.shards = shards
			state.hosts = hosts
		} else {
			state.shards = []
			state.hosts = []
		}
	}
}

export default mutations
