<template>
	<div>
		<div class="menu-line">
			<v-select-project
				class="menu-line__item"
				v-model="filters.projectId"
				label="Проект"
			/>
			<v-menu
				v-model="menu"
				:close-on-content-click="false"
				:nudge-right="40"
				transition="scale-transition"
				offset-y
				min-width="auto"
			>
				<template v-slot:activator="{ on, attrs }">
					<div class="d-flex align-center">
						<v-text-field
							class="menu-line__item"
							v-model="filters.date"
							label="Дата"
							prepend-icon="mdi-calendar"
							readonly
							outlined
							hide-details
							dense
							v-bind="attrs"
							v-on="on"
						/>
					</div>
				</template>
				<v-date-picker
					v-model="filters.date"
					locale="ru"
					first-day-of-week="1"
					scrollable
					no-title
					:max="new Date().toISOString().substr(0, 10)"
				>
				</v-date-picker>
			</v-menu>
			<v-autocomplete
				class="menu-line__item"
				v-model="filters.strategy"
				:items="OPTIONS_STRATEGY"
				v-bind="{
					outlined: true,
					dense: true,
					hideDetails: true,
					light: true,
					clearable: true,
					label: 'Стратегия пересбора'
				}"
			/>
			<v-autocomplete
				class="menu-line__item"
				v-model="filters.limit"
				:items="OPTIONS_LIMIT"
				v-bind="{
					outlined: true,
					dense: true,
					hideDetails: true,
					light: true,
					label: 'Лимит запросов на странице'
				}"
			/>
		</div>
		<v-tabs
			:value="filters.source"
			class="mb-1"
			grow
		>
			<v-tab
				v-for="item of OPTIONS_SOURCE"
				:key="item.id"
				:tab-value="item.id"
				@change="changeTab(item.id)"
			>
				<span>{{ item.text }}</span>
			</v-tab>
		</v-tabs>
	</div>
</template>

<script>
import updateUrl from '@/mixins/updateUrlMixin'
import { OPTIONS_SOURCE } from '@/const'
import { OPTIONS_LIMIT, DEFAULT_LIMIT, OPTIONS_STRATEGY } from '../filtersOptions'

import selectProject from '@/components/selectProject'

export default {
	components: {
		'v-select-project': selectProject
	},
	mixins: [updateUrl],
	props: {
		urlFilters: {
			type: Object,
			required: true
		}
	},
	data () {
		return {
			filters: {
				date: '',
				projectId: '',
				source: '',
				limit: DEFAULT_LIMIT,
				strategy: ''
			},
			menu: false,
			OPTIONS_SOURCE,
			OPTIONS_LIMIT,
			OPTIONS_STRATEGY
		}
	},
	created () {
		this.setFilters()
	},
	methods: {
		setFilters () {
			for (const key in this.urlFilters) {
				if (this.urlFilters[key]) {
					this.filters[key] = this.urlFilters[key]
				}
			}
		},
		changeTab (value) {
			if (value !== this.filters.source) {
				this.filters.source = value
			}
		}
	},
	watch: {
		filters: {
			async handler (value) {
				if (!value.date || isNaN(value.projectId) || !value.source) return
				this.updateUrl({
					date: value.date,
					project_id: value.projectId,
					source: value.source,
					strategy: value.strategy || undefined,
					limit: value.limit === DEFAULT_LIMIT ? undefined : value.limit,
					offset: undefined
				})
			},
			deep: true
		}
	}
}
</script>

<style lang="scss" scoped>
.menu-line {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 10px;
	margin-bottom: 24px;

	&__item {
		max-width: 300px;
	}
}
</style>
