import Vue from 'vue'

const mutations = {
	/* Запись списка проектов для отдельного клиента хранилище */
	pushForceProjects (state, payload) {
		let forceProjects = []
		if (payload && payload.data) {
			forceProjects = payload.data
			Vue.set(state, 'forceProjects', forceProjects)
		}
	},

	/* Запись истории сборов для отдельного проекта */
	pushForceProjectHistory (state, payload) {
		let forceProjectHistory = {}
		if (payload && payload.data) {
			forceProjectHistory = payload.data
			Vue.set(state, 'forceProjectHistory', forceProjectHistory)
		}
	}
}

export default mutations
