<template>
	<div>
		<h1 class="main-title mb-3">{{title}}</h1>
		<br/>
		<v-filters :urlFilters="urlFilters"/>
		<v-data-table
			class="recollect-queries__table"
			:loading="isLoading"
			:headers="headers"
			:items="sortedQueries"
			:items-per-page="-1"
			hide-default-footer
			@update:sort-by="setOrderBy"
			@update:sort-desc="setSortDirection"
		>
			<template v-slot:item.index="{ index }">
				{{ index + urlFilters.limit * (page - 1) + 1}}
			</template>
		</v-data-table>
		<div class="recollect-queries__pagination" v-if="showPagination">
			<v-pagination :value="page" :length="totalPages" :total-visible="visiblePagesCount" v-on:input="handlePageChange"/>
		</div>
	</div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { isEqual } from 'lodash'
import { validateFilterFromOptions, validateFilterNumber } from '@/function/validateFilter'
import { OPTIONS_SOURCE } from '@/const'
import updateUrl from '@/mixins/updateUrlMixin'

import filters from './filters.vue'
import { OPTIONS_STATUS, OPTIONS_LIMIT, DEFAULT_LIMIT, OPTIONS_STRATEGY } from '../filtersOptions'

export default {
	metaInfo () {
		return {
			title: this.title
		}
	},
	components: {
		'v-filters': filters
	},
	mixins: [updateUrl],
	data () {
		return {
			title: 'Пересборы: запросы',
			page: 1,
			totalPages: 1,
			visiblePagesCount: 6,
			isLoading: false,
			headers: [
				{
					text: '№',
					value: 'index',
					sortable: false,
					align: 'center',
					divider: true
				},
				{
					text: 'Запрос',
					value: 'query',
					sortable: false,
					align: 'center',
					width: '18%',
					divider: true
				},
				{
					text: 'Дата',
					value: 'date',
					align: 'center',
					divider: true
				},
				{
					text: 'ID проекта',
					value: 'project_id',
					align: 'center',
					divider: true
				},
				{
					text: 'Поисковая система',
					value: 'source',
					align: 'center',
					divider: true
				},
				{
					text: 'Источник',
					value: 'vendor',
					align: 'center',
					divider: true
				},
				{
					text: 'WS',
					value: 'ws',
					align: 'center',
					divider: true
				},
				{
					text: 'Стратегия',
					value: 'strategy',
					align: 'center',
					divider: true
				},
				{
					text: 'Позиция',
					value: 'position',
					align: 'center',
					divider: true
				},
				{
					text: 'Предыдущая позиция',
					value: 'prev_position',
					align: 'center',
					divider: true
				},
				{
					text: 'Статус',
					value: 'status',
					align: 'center',
					divider: true
				}
			]
		}
	},

	computed: {
		...mapState({
			recollectQueries: state => state.recollect.recollectQueries,
			profile: state => state.profile
		}),
		defaultProjectId () {
			return this.profile && this.profile.properties && this.profile.properties.default_project_id
		},
		urlFilters () {
			const { date, project_id, source, strategy, limit, offset, order_by, order_direction } = this.$route.query
			return {
				date: date || new Date().toISOString().split('T')[0],
				projectId: validateFilterNumber(+project_id, this.defaultProjectId),
				source: validateFilterFromOptions(source, OPTIONS_SOURCE, 'yandex'),
				strategy: validateFilterFromOptions(strategy, OPTIONS_STRATEGY),
				orderBy: order_by || 'ws',
				orderDirection: order_direction || 'DESC',
				limit: validateFilterFromOptions(+limit, OPTIONS_LIMIT, DEFAULT_LIMIT, false, true),
				offset: validateFilterNumber(offset, 0)
			}
		},
		isLimitReached () {
			return this.sortedQueries.length >= this.urlFilters.limit
		},
		showPagination () {
			return this.isLimitReached || this.totalPages > 1
		},
		sortedQueries () {
			return this.recollectQueries.map(item => {
				const status = OPTIONS_STATUS.find(option => +option.id === +item.status)
				return {
					...item,
					timestamp: item.date && new Date(item.date).getTime(),
					status: status ? status.text : item.status
				}
			}).sort((a, b) => b.timestamp - a.timestamp)
		},
		nextPage () {
			return this.isLimitReached ? this.page + 1 : this.page
		},
		queryOrderBy () {
			if (!this.urlFilters.orderBy || !this.urlFilters.orderDirection) return
			const orderDirection = this.urlFilters.orderDirection === 'DESC' ? '' : '-'
			return `${orderDirection}${this.urlFilters.orderBy}`
		}
	},

	async mounted () {
		await this.initData()
	},

	methods: {
		...mapActions({
			getRecollectQueries: 'recollect/getRecollectQueries'
		}),

		async initData () {
			try {
				this.isLoading = true
				await this.getRecollectQueries({
					date: this.urlFilters.date,
					projectId: this.urlFilters.projectId,
					source: this.urlFilters.source,
					query: {
						strategy: this.urlFilters.strategy,
						order_by: this.queryOrderBy,
						limit: this.urlFilters.limit,
						offset: String(this.urlFilters.offset)
					}
				})
			} catch (error) {
				this.$notify({ type: 'error', title: error })
			} finally {
				this.isLoading = false
			}
		},

		async handlePageChange (value) {
			if (this.page !== value) {
				this.page = value
				this.updateUrl({ offset: this.urlFilters.limit * (value - 1) })
			}
		},
		setOrderBy (value) {
			if (value !== this.urlFilters.orderBy) {
				this.updateUrl({
					order_by: value,
					order_direction: 'ASC'
				})
			}
		},
		setSortDirection (value) {
			switch (value) {
			case true:
				this.updateUrl({ order_direction: 'DESC' })
				break
			case false:
				this.updateUrl({ order_direction: 'ASC' })
				break
			default:
				this.updateUrl({ order_direction: undefined })
			}
		}
	},

	watch: {
		'$route.query' (to, from) {
			if (!isEqual(to, from)) {
				this.initData()
			}
		},
		nextPage (value) {
			this.totalPages = Math.max(this.totalPages, value)
		},
		'urlFilters.offset': {
			handler (value) {
				this.page = Math.floor(value / this.urlFilters.limit + 1)
				this.totalPages = this.nextPage
			},
			immediate: true
		}
	}
}
</script>

<style>
.recollect-queries__table {
	margin-top: 30px;
	margin-bottom:30px;
}
</style>
