import Vue from 'vue'
import Router from 'vue-router'
import Url from 'url-parse'

import { isLoggedIn, logOut, refresh, startLogin } from '@/function/auth.js'
Vue.use(Router)

const clearContainer = () => import('@/services/clearContainer.vue')

/* Проверка аутентификации */
const beforeEnterIndex = function (to, from, next) {
	if (isLoggedIn()) {
		next({ name: 'dashboard' })
	} else {
		next({ name: 'login' })
	}
}

/* Сквозной переход */
const beforeEnterRedirect = function (config) {
	return function (to, from, next) {
		const url = Url(to.query.q || '/')
		if (url.hostname === window.location.hostname) {
			url.set('host', Url(config.mainUrl).host)
		}

		location.assign(url.href)
	}
}

/* Разлогирование */
const beforeEnterLogOut = function (config, to, from, next) {
	if (logOut(config)) {
		next({ name: 'login', query: { to: from.fullPath } })
	} else {
		next()
	}
}

/* Проверка прав доступа к проекту */
const beforeEnterDashboard = function (config, to, from, next) {
	if (isLoggedIn()) {
		refresh(config)
		next()
	} else {
		(async () => {
			startLogin(config).then(tokens => {
				next()
			}, () => {
				next({ name: 'login', query: { to: to.fullPath } })
			})
		})()
	}
}

/* Центральный роутинг */
export function router (config) {
	const routes = [
		/* Главная страница */
		{
			name: 'index',
			path: '/',
			beforeEnter: beforeEnterIndex
		},

		/* Сквозной переход (требуется для верификации урлов в меню) */
		{
			name: 'redirect',
			path: '/redirect',
			beforeEnter: beforeEnterRedirect(config)
		},

		/* Страница аутентификации */
		{
			name: 'auth',
			path: '/auth',
			component: clearContainer,
			children: [
				{
					name: 'login',
					path: '/login',
					component: () => import('../services/auth/pages/login.vue')
				},
				{
					name: 'logout',
					path: '/logout',
					beforeEnter: (...args) => beforeEnterLogOut(config, ...args)
				}
			]
		},

		/* Дашборд */
		{
			name: 'dashboard',
			path: '/dashboard',
			component: () => import('../components/appContainer'),
			beforeEnter: (...args) => beforeEnterDashboard(config, ...args),
			redirect: { name: 'data-save' },
			children: [
				{
					name: 'tasks',
					path: '/tasks',
					component: () => import('../services/dashboard/pages/tasks/')
				},
				{
					name: 'listings',
					path: '/listings',
					component: () => import('../services/dashboard/pages/listings/')
				},
				{
					name: 'categories',
					path: '/categories',
					component: () => import('../services/dashboard/pages/categories/')
				},
				{
					name: 'cards',
					path: '/cards',
					component: () => import('../services/dashboard/pages/cards/')
				},
				{
					name: ' tools',
					path: '/tools',
					component: clearContainer,
					children: [
						{
							name: 'i18n',
							path: '/tools/i18n',
							component: clearContainer,
							children: [
								{
									name: 'toolsI18nEntityTypes',
									path: '/tools/i18n/entity-types',
									component: () => import('../services/dashboard/tools/i18n/pages/i18nEntityTypes')
								},
								{
									name: 'toolsI18nTranslates',
									path: '/tools/i18n/translates',
									component: () => import('../services/dashboard/tools/i18n/pages/i18nTranslates')
								},
								{
									name: 'toolsI18nLabels',
									path: '/tools/i18n/labels',
									component: () => import('../services/dashboard/tools/i18n/pages/i18nLabels')
								},
								{
									name: 'toolsI18nLabelsTranslates',
									path: '/tools/i18n/labels-translates',
									component: () => import('../services/dashboard/tools/i18n/pages/i18nLabelsTranslates')
								},
								{
									name: 'toolsI18nHintsTranslates',
									path: '/tools/i18n/hints-translates',
									component: () => import('../services/dashboard/tools/i18n/pages/i18nHintsTranslates')
								},
								{
									name: 'toolsI18nWizardsGroup',
									path: '/tools/i18n/wizards-group',
									component: () => import('../services/dashboard/tools/i18n/pages/wizardGroup')
								},
								{
									name: 'toolsI18nWizardsCatalog',
									path: '/tools/i18n/toolsI18nWizardsCatalog',
									component: () => import('../services/dashboard/tools/i18n/pages/wizardsCatalog')
								},
								{
									name: 'toolsI18nCtr',
									path: '/tools/i18n/ctr',
									component: () => import('../services/dashboard/tools/i18n/pages/ctr')
								}
							]
						}
					]
				},
				{
					name: 'data-save',
					path: '/data/save',
					component: () => import('../services/dashboard/pages/data/save/')
				},
				{
					name: 'data-sessions',
					path: '/data/sessions',
					component: () => import('../services/dashboard/pages/data/sessions/')
				},
				{
					name: 'data-sessions-log',
					path: '/data/seoapi_session_log',
					component: () => import('../services/dashboard/pages/data/sessionsLog/')
				},
				{
					name: 'data-fluke-sessions-log',
					path: '/data/fluke_session_log',
					component: () => import('../services/dashboard/pages/data/sessionsLog/')
				},
				{
					name: 'data-recollect',
					path: '/data/recollect',
					component: () => import('../services/dashboard/pages/data/recollect/')
				},
				{
					name: 'data-recollect-queries',
					path: '/data/recollect/queries/',
					component: () => import('../services/dashboard/pages/data/recollect/recollectQueries/')
				},
				{
					name: 'data-projects-save',
					path: '/data/projects/save',
					component: () => import('../services/dashboard/pages/data/projects/save/')
				},
				{
					name: 'data-projects-resave',
					path: '/data/projects/resave',
					component: () => import('../services/dashboard/pages/data/projects/resave/')
				},
				{
					name: 'data-projects-collection',
					path: '/data/projects/collection',
					component: () => import('../services/dashboard/pages/data/projects/collection/')
				},
				{
					name: 'data-projects-save-not-saved-ch',
					path: '/data/projects/save/not-saved-ch/:id',
					component: () => import('../services/dashboard/pages/data/projects/save/not-saved-ch')
				},
				{
					name: 'data-segments-count-all',
					path: '/data/segments/count-all',
					component: () => import('../services/dashboard/pages/data/segments/count-all/')
				},
				{
					name: 'data-segments-count',
					path: '/data/segments/count',
					component: () => import('../services/dashboard/pages/data/segments/count/')
				},
				{
					name: 'data-features',
					path: '/data/features',
					component: () => import('../services/dashboard/pages/data/features')
				},
				{
					name: 'data-segments-all',
					path: '/data/segments/all',
					component: () => import('../services/dashboard/pages/data/segments/all/')
				},
				{
					name: 'frequency',
					path: '/frequency',
					component: () => import('../services/dashboard/pages/frequency/index.vue')
				},
				{
					name: 'frequencyProjects',
					path: '/frequency/projects',
					component: () => import('../services/dashboard/pages/frequency/projects/index.vue')
				},
				{
					name: 'frequency-yandex',
					path: '/frequency/yandex',
					component: () => import('../services/dashboard/pages/frequency/sessionWSyandex/index.vue')
				},
				{
					name: 'gsc',
					path: '/gsc',
					component: () => import('../services/dashboard/pages/gsc/gsc.vue')
				},
				{
					name: 'projectIntegration',
					path: '/dataforce/project/integration',
					component: () => import('../services/dashboard/pages/dataforce/projectIntegration')
				},
				{
					name: 'dataUpdatesSaved',
					path: '/data/updates/saved',
					component: () => import('../services/dashboard/pages/data/updates/saved')
				}
			]
		}
	]

	return new Router({
		mode: 'history',
		base: process.env.BASE_URL,
		routes
	})
}
