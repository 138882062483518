import constants from '@/const/'
import errors from '@/store/errors'

const actions = {
	getChapters ({ commit, rootState }) {
		return new Promise((resolve, reject) => {
			rootState.request({
				type: constants.METHODS.GET,
				apiName: 'seowork',
				url: '/project/ctr/list/folders',
				auth: true
			}).then(result => {
				commit('pushChapters', result)
				resolve(true)
			}, () => {
				reject(Error(errors.GET_DATA))
			})
		})
	},
	setChapter: function ({ rootState }, input) {
		return new Promise((resolve, reject) => {
			rootState.request({
				type: constants.METHODS.POST,
				url: '/project/ctr/folder',
				apiName: 'seowork',
				auth: true,
				body: input
			})
				.then(result => {
					resolve(result && result.status === 200)
				}, () => {
					reject(Error(errors.POST))
				})
		})
	},

	setDictionary: function ({ rootState }, input) {
		return new Promise((resolve, reject) => {
			rootState.request({
				type: constants.METHODS.POST,
				url: '/project/ctr/dictionary',
				apiName: 'seowork',
				auth: true,
				body: input
			})
				.then(result => {
					resolve(result && result.status === 200)
				}, () => {
					reject(Error(errors.POST))
				})
		})
	},

	getCtrStructure ({ commit, rootState }) {
		return new Promise((resolve, reject) => {
			rootState.request({
				type: constants.METHODS.GET,
				apiName: 'seowork',
				url: '/project/ctr/list/structure',
				auth: true
			}).then(result => {
				commit('pushCtrStructure', result)
				resolve(true)
			}, () => {
				reject(Error(errors.GET_DATA))
			})
		})
	},

	getDictionaryValues ({ commit, rootState }, input) {
		return new Promise((resolve, reject) => {
			rootState.request({
				type: constants.METHODS.GET,
				apiName: 'seowork',
				url: '/project/ctr/dictionary/values',
				auth: true,
				query: {
					id: input
				}
			}).then(result => {
				commit('pushDictionaryValues', result)
				resolve(true)
			}, () => {
				reject(Error(errors.GET_DATA))
			})
		})
	},

	setCtrStructure: function ({ rootState }, input) {
		return new Promise((resolve, reject) => {
			rootState.request({
				type: constants.METHODS.POST,
				url: '/project/ctr/list/structure',
				apiName: 'seowork',
				auth: true,
				body: {
					data: input
				}
			})
				.then(result => {
					resolve(result && result.status === 200)
				}, () => {
					reject(Error(errors.POST))
				})
		})
	}
}

export default actions
