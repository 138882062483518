<template>
	<div class="export">
		<p class="export__title">Экспорт</p>
		<v-text-field
			class="export__offset"
			v-model="offset"
			label="Оффсет"
			outlined
			hide-details
			dense
			type="number"
			clearable
			autofocus
		/>
		<div class="export__buttons">
			<v-btn
				color="blue darken-1"
				text
				@click="onClose"
			>
				Отмена
			</v-btn>
			<v-btn
				color="primary"
				@click="exportLogs"
			>
				Экспорт
			</v-btn>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		sessionId: String,
		source: String,
		type: String,
		domain: String,
		onClose: {
			type: Function,
			default: () => {}
		}
	},
	data () {
		return {
			offset: 0
		}
	},
	computed: {
		exportLink () {
			const query = {
				session_id: this.sessionId,
				source: this.source,
				session_offset: this.offset ? Number(this.offset) : 0
			}
			if (this.type) {
				query.data_type = this.type
			}
			return this.domain + '/api/session_log/archived_file?' + new URLSearchParams(query).toString()
		}
	},
	mounted () {
		window.addEventListener('keypress', this.handlePressEnter)
	},
	methods: {
		exportLogs () {
			window.open(this.exportLink, '_blank')
			this.onClose()
		},
		handlePressEnter (e) {
			if (e.key === 'Enter') {
				this.exportLogs()
			}
		}
	},
	beforeDestroy () {
		window.removeEventListener('keypress', this.handlePressEnter)
	}
}
</script>

<style lang="scss" scoped>
.export {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 16px;
	background-color: #ffffff;
	&__title {
		font-size: 20px;
		margin-bottom: 20px;
	}
	&__offset {
		margin-bottom: 24px;
	}
	&__buttons {
		width: 100%;
		display: flex;
		justify-content: space-around;
	}
}
</style>
