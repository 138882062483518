import { render, staticRenderFns } from "./dialogChapter.vue?vue&type=template&id=bcb85654&scoped=true"
import script from "./dialogChapter.vue?vue&type=script&lang=js"
export * from "./dialogChapter.vue?vue&type=script&lang=js"
import style0 from "./dialogChapter.vue?vue&type=style&index=0&id=bcb85654&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bcb85654",
  null
  
)

export default component.exports